const children = [
  {
    name: "My Nutrafol",
    url: "/account/",
  },
  {
    name: "Orders",
    url: `/account/orders/`,
  },
  {
    name: "Subscriptions",
    url: `/account/subscriptions/`,
  },
  {
    name: "Profile",
    url: "/account/profile/",
  },
  {
    name: "Rewards",
    url: "/account/rewards/",
  },
  {
    name: "Get Support",
    url: "/contact-us/",
  },
  {
    name: "Log Out",
    url: "",
  },
]

export const data = [
  {
    name: "shop",
    childrenTitle: "Hair Growth Supplements",
    subCategoriesTitle: "Shop by Root Cause",
    servicesTitle: "Services",
    isAlt: true,
    link: {
      text: "Products",
      url: "#",
    },
    children: [
      {
        name: "Women",
        subtext: "For women 18-44",
        subtextDesktop: "For women 18-44",
        url: "/nutrafol-core-for-women/",
      },
      {
        name: "Women's Vegan",
        subtext:
          "For women 18-44 with a plant-based lifestyle experiencing signs of hair thinning.",
        subtextDesktop: "For women 18-44 with plant-based lifestyles",
        url: "/womens-vegan/",
        className: "vegan",
      },
      {
        name: "Women's Balance",
        subtext: "For women 45+ experiencing signs of hair thinning.",
        subtextDesktop: "For women 45+",
        url: "/women-balance/",
      },
      {
        name: "Postpartum",
        subtext:
          "For women in the first year post-childbirth <br class='br-sm'/>experiencing signs of hair thinning and shedding.",
        subtextDesktop: "For women in the first year post-childbirth",
        url: "/nutrafol-postpartum-for-women/",
      },
      {
        name: "Men",
        subtext:
          "For men 18+ experiencing signs of hair thinning and less scalp coverage.",
        subtextDesktop: "Switch to Nutrafol Men",
        url: "/men/products/thinning-hair-growth-supplement/",
        isMens: true,
      },
    ],
    subCategories: [
      {
        name: "Stress",
        url: "/de-stress-hair-growth-duo/",
      },
      {
        name: "Nutrition",
        url: "/gut-microbiome-hair-growth-duo/",
      },
      {
        name: "Aging",
        url: "/strengthening-hair-growth-duo/",
      },
      {
        name: "Hormones",
        url: "/hormone-support-hair-growth-duo/",
      },
      {
        name: "Lifestyle",
        url: "/toxin-cleanse-hair-growth-duo/",
      },
      {
        name: "Metabolism",
        url: "/energy-hair-growth-duo/",
      },
      {
        name: "Scalp Care",
        url: "/products/#scalp",
      },
    ],
    services: [
      {
        name: "GrowthPlan+",
        subtext:
          "An all-inclusive plan for hair health, personalized for you by our naturopathic doctors.",
        url: "/growthplanplus/",
      },
    ],
  },
  {
    name: "science",
    link: {
      text: "Science",
      url: "#",
    },
    children: [
      {
        type: "link",
        name: "Our Approach",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/17ZvJIwTygqidGRpBnkUk4/26b30c63a133e41ef4f5523e5524f289/nav-approach.jpg",
        alt: "",
        text: "The science behind our whole-body approach to hair health.",
        url: "/science/",
      },

      {
        type: "link",
        name: "Clinical Studies",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/USpT7fDrB6Mqk7Sd3t1ip/84c7bc128187070e38f2087e6ee94be3/nav-standarts.jpg",
        alt: "",
        text: "A deep dive into how we put our hair growth formulas to the test.",
        url: "/clinical/",
      },
      {
        type: "link",
        name: "Ingredients",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/15gXMu9jA2ToKozg60xy8W/15515d879a161b1760df4eda0dcc84e7/nav-ingredients.jpg",
        alt: "",
        text: "How our naturally potent ingredients deliver results.",
        url: "/ingredients/",
      },
      {
        type: "link",
        name: "Standards",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/4x7ElyBnGd9aKi06V3HNUt/b8f048d883ae5532cf0cf39a08ba28a5/nav-standards.jpg",
        alt: "",
        text: "Our commitment to quality, safety, and delivering results.",
        url: "/standards/",
      },
    ],
  },
  {
    name: "results",
    link: {
      text: "Results",
      url: "/results/",
    },
  },
  {
    name: "shed",
    link: {
      text: "Shed the Silence",
      url: "/shedthesilence/",
    },
  },
  {
    name: "account",
    isMobileOnly: true,
    link: {
      text: "Account",
      url: "#",
    },
    children,
  },
]

export const accordionNavigationData = [
  {
    type: "hgn",
    open: true,
    name: "products",
    title: "Hair Growth Supplements",
    children: [
      {
        type: "link",
        title: "Women",
        subtitle: "For women 18-44",
        url: "/nutrafol-core-for-women/",
      },
      {
        type: "link",
        title: "Women's Vegan",
        subtitle: "For women 18-44 with plant-based lifestyles",
        url: "/womens-vegan/",
      },
      {
        type: "link",
        title: "Women's Balance",
        subtitle: "For women 45+",
        url: "/women-balance/",
      },
      {
        type: "link",
        title: "Postpartum",
        subtitle: "For women in the first year post-childbirth",
        url: "/nutrafol-postpartum-for-women/",
      },
      {
        type: "link",
        title: "Men",
        subtitle: "Switch to Nutrafol Men",
        url: "/men/products/thinning-hair-growth-supplement/",
        isMens: true,
      },
      {
        type: "button",
        label: "Take the Quiz to Find Your Formula",
        url: "/quiz/",
      },
    ],
  },
  {
    type: "accordion",
    open: true,
    name: "services",
    title: "Services",
    children: [
      {
        type: "link",
        title: "GrowthPlan+",
        subtitle:
          "An all-inclusive plan for hair health, personalized for you by our naturopathic doctors.",
        url: "/growthplanplus/",
      },
    ],
  },
  {
    type: "accordion",
    open: false,
    name: "science",
    title: "Science",
    children: [
      {
        type: "link",
        title: "Our Approach",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/17ZvJIwTygqidGRpBnkUk4/26b30c63a133e41ef4f5523e5524f289/nav-approach.jpg",
        alt: "",
        subtitle: "The science behind our whole-body approach to hair health.",
        url: "/science/",
      },
      {
        type: "link",
        title: "Clinical Studies",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/USpT7fDrB6Mqk7Sd3t1ip/84c7bc128187070e38f2087e6ee94be3/nav-standarts.jpg",
        alt: "",
        subtitle:
          "A deep dive into how we put our hair growth formulas to the test.",
        url: "/clinical/",
      },
      {
        type: "link",
        title: "Ingredients",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/15gXMu9jA2ToKozg60xy8W/15515d879a161b1760df4eda0dcc84e7/nav-ingredients.jpg",
        alt: "",
        subtitle: "How our naturally potent ingredients deliver results.",
        url: "/ingredients/",
      },
      {
        type: "link",
        title: "Standards",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/4x7ElyBnGd9aKi06V3HNUt/b8f048d883ae5532cf0cf39a08ba28a5/nav-standards.jpg",
        alt: "",
        subtitle: "Our commitment to quality, safety, and delivering results.",
        url: "/standards/",
      },
    ],
  },
  {
    type: "accordion",
    open: false,
    name: "causes",
    title: "Shop by Root Cause",
    children: [
      {
        type: "link",
        title: "Stress",
        url: "/de-stress-hair-growth-duo/",
      },
      {
        type: "link",
        title: "Nutrition",
        url: "/gut-microbiome-hair-growth-duo/",
      },
      {
        type: "link",
        title: "Aging",
        url: "/strengthening-hair-growth-duo/",
      },
      {
        type: "link",
        title: "Hormones",
        url: "/hormone-support-hair-growth-duo/",
      },
      {
        type: "link",
        title: "Lifestyle",
        url: "/toxin-cleanse-hair-growth-duo/",
      },
      {
        type: "link",
        title: "Metabolism",
        url: "/energy-hair-growth-duo/",
      },
      {
        type: "link",
        title: "Scalp Care",
        url: "/products/#scalp",
      },
    ],
  },
  {
    type: "link",
    url: "/results/",
    name: "results",
    title: "Results",
  },
  {
    type: "link",
    name: "sts",
    title: "Shed the Silence",
    url: "/shedthesilence/",
  },
]

export const ACCPATH = "/account/"

export const menuItems = [
  {
    title: "myNutrafol",
    link: ACCPATH,
    class: "account-menu-item",
  },
  {
    title: "Subscriptions",
    link: "/account/subscriptions/",
    class: "account-menu-item",
  },
  {
    title: "Order History",
    link: "/account/orders/",
    class: "account-menu-item",
  },

  {
    title: "Profile",
    link: "/account/profile/",
    class: "account-menu-item",
  },
  {
    title: "Rewards",
    link: "/account/rewards/",
    class: "account-menu-item",
  },
  {
    title: "Log Out",
    link: "/logout",
    class: "account-menu-item",
  },
]
