import React, { useState, useEffect } from "react"

import { getCustomerCampaigns } from "../queries/index"
import { client } from "../apollo/client"

const defaultState = {
  isLoggedIn: false,
  atcData: null,
  activeOption: 0,
  site: null,
  customerCampaigns: null,
}

const UserContext = React.createContext(defaultState)
  
const UserProvider = (props) => {

  const { children } = props

  const [customer, setCustomer] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [skipClientCalls, setSkipClientCalls] = useState(false)
  const [activeOption, setActiveOption] = useState(0)
  const [atcData, setAtcData] = useState(null)
  const [site, setSite] = useState(null)
  const [upsellData, setUpsellData] = useState(null)
  const [customerCampaigns, setCustomerCampaigns] = useState(null)
  const fetchCustomerCampaigns = () => {
    // Execute the query using client.query() since we have no access to apollo context
    client
      .query({
        query: getCustomerCampaigns,
        fetchPolicy: "network-only", // or 'no-cache'
      })
      .then((result) => {
        setCustomerCampaigns(result?.data?.CustomerCampaigns || [])
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (isLoggedIn && !skipClientCalls) {
      fetchCustomerCampaigns()
    }
  }, [isLoggedIn])

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        activeOption,
        setActiveOption,
        atcData,
        setAtcData,
        site,
        setSite,
        upsellData,
        setUpsellData,
        customer,
        customerCampaigns,
        fetchCustomerCampaigns,
        skipClientCalls,
        setSkipClientCalls,
        client,
        logUserOut: () => {
          setIsLoggedIn(false)
          setCustomer(null)
        },
        logUserIn: (c = null) => {
          setIsLoggedIn(true)
          if (c) {
            setCustomer(c)
          }
        },
      }}
    >
      {children}
    </UserContext.Provider>
  )

}

export default UserContext

export { UserProvider }