const readCookie = function (name) {
  if (typeof window !== `undefined`) {
    const nameEQ = name + "="
    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) {
        const value = c.substring(nameEQ.length, c.length)
        return value.replace(/\+/g, " ")
      }
    }
    return null
  }
  return null
}

const setCookie = function (name, value, days, path = "/") {
  if (typeof window !== `undefined`) {
    let date, expires
    if (days) {
      date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toUTCString()
    } else {
      expires = ""
    }
    document.cookie =
      name + "=" + value + expires + "; path=" + path + ";secure"
  }
}

const setCookieInfluencer = function () {
  if (typeof window !== `undefined`) {
    const path = window.location.pathname
    setCookie("influencer", path.replace(/[\W_]/gm, ""), 365, path)
  }
}

const deleteCookie = function (name) {
  if (typeof window !== `undefined`) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
  }
}

export { readCookie, setCookie, deleteCookie, setCookieInfluencer }
