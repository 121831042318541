import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"

import { ButtonNova } from "@nutrafol/nutrafol-ui-kit/dist/ButtonNova"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { handleNavigationSegmentEvent } from "./utils/segmentEvents"

import OpenMenuIcon from "../../../../assets/icons/wild/open-menu-icon.svg"
import CloseMenuIcon from "../../../../assets/icons/wild/close-menu-icon.svg"
import HeaderCartIcon from "./headercarticon"
import UserContext from "../../../../context/UserContext"
import AccountSVG from "../../../../assets/icons/nova/user.svg"
//import AccountSVGWhite from "../../../../assets/icons/wild/account-icon-wild-white.svg"
import SearchIcon from "../../../../assets/icons/nova/search.svg"

import { SearchContext } from "../../../search/searchcontext"
import { SearchIconContainerVariant } from "../../../search/search.styles"

const tabletFold = 1024

const HamburgerOpenCloseContainer = styled.div`
  @media (min-width: ${tabletFold}px) {
    display: none;
  }
`

const HamburgerOpenCloseIcon = styled.button`
  padding: 13px 11px;
  height: 52px;
  .close-nav {
    margin-left: 2px;
    margin-right: 2px;
  }
`

const QuizButtonBox = styled(Box)`
  .tablet-button {
    display: none;
    ${queries.medium`
      display: block;
    `}
    @media (min-width: ${tabletFold}px) {
      display: none;
    }
  }
  .desktop-button {
    display: none;
    @media (min-width: ${tabletFold}px) {
      display: block;
    }
  }

  .Primary.Default.Noicon {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
`
const CartBox = styled(Box)`
  &.regular {
    margin-right: 5px;
    @media (min-width: 365px) {
      margin-right: 10px;
    }
    @media (min-width: 1024px) {
      margin-right: 0;
    }
  }
`

const BurgerBox = styled(Box)`
  width: 40px;
  @media (min-width: 350px) {
    width: 40px;
  }
  @media (min-width: ${tabletFold}px) {
    display: none;
  }

  @media (max-width: 1023px) {
    button {
      height: 52px;
      svg.close-nav {
        width: 16px;
        height: 16px;
      }
      svg.open-nav {
        width: 20px;
        height: 16px;
      }
    }
  }
`
const AccountBox = styled(Box)`
  svg {
    width: 24px;
    height: 24px;
  }
  &.simple-account {
    padding: 7px;
  }

  margin-left: 16px;
  @media (min-width: 1024px) {
    padding-right: 0;
    margin-left: 32px;
  }
`

const HeaderIcons = (props) => {
  const {
    navIsOpen,
    showCart,
    showBurger = true,
    isProductPage,
    isAccountPage,
    isQuizLp,
    toggleMobileNav,
    isSimple = false,
    classNameBurger = `hide-lg-up`,
    isIconVisibleOnRightSideOnly = false,
    location,
  } = props

  const mageURL = process.env.GATSBY_MAGENTO_URL
  const ctaText = "Take the Quiz"
  //const ctaTextAcct = `My Account`
  const dest = !isQuizLp ? "quiz/" : "quiz/?skip=true"

  const { toggleSearchDropdown, closeSearchDropdown } =
    useContext(SearchContext)

  const onClick = () => {
    segmentEvent("Marketing CTA Clicked", {
      destination: dest,
      module_location: "navigation",
      text: ctaText,
      type: "button",
    })
    window.location = mageURL + dest
  }

  const getAccountButton = (userState, className = ``) => {
    return (
      <AccountBox
        className={`${
          isSimple ? "simple-account" : "show-desktop"
        } ${className}`}
      >
        {userState.isLoggedIn ? (
          <Link
            to={"/account/"}
            onClick={() =>
              handleNavigationSegmentEvent({
                destination: "/account/",
              })
            }
          >
            <AccountSVG />
          </Link>
        ) : (
          <Link
            to={"/customer/account/login/"}
            onClick={() =>
              handleNavigationSegmentEvent({
                destination: "/customer/account/login/",
              })
            }
          >
            <AccountSVG />
          </Link>
        )}
      </AccountBox>
    )
  }

  return (
    <UserContext.Consumer>
      {(userState) => (
        <>
          {isSimple || isAccountPage ? null : (
            <QuizButtonBox mr={[0, 0, 15, 30]}>
              <ButtonNova
                label={ctaText}
                ctaLink={mageURL + dest}
                className="desktop-button header-quiz-link"
                onClick={onClick}
              />
            </QuizButtonBox>
          )}
          {isIconVisibleOnRightSideOnly && !isSimple ? (
            <SearchIconContainerVariant className="search-icon-container">
              <button
                onClick={() => {
                  if (navIsOpen) {
                    toggleMobileNav(false)
                  }
                  toggleSearchDropdown()
                }}
                aria-label={"search"}
                className="flex items-center"
              >
                <SearchIcon />
              </button>
            </SearchIconContainerVariant>
          ) : null}
          {(isSimple && isIconVisibleOnRightSideOnly) || isAccountPage
            ? null
            : getAccountButton(userState, "show-lg-up")}
          {isSimple && !showCart ? (
            <Box className={`hide-lg-up`}>{getAccountButton(userState)}</Box>
          ) : null}
          {showCart ? (
            <CartBox
              className={`${isSimple ? "simple" : "regular"}`}
              ml={[16, 16, 24, 32]}
            >
              <HeaderCartIcon location={location} />
            </CartBox>
          ) : null}
          {!isSimple && !isAccountPage && showBurger ? (
            <BurgerBox className={classNameBurger}>
              <HamburgerOpenCloseContainer className="burger-container">
                <HamburgerOpenCloseIcon
                  id={`HamburgerOpenCloseIcon`}
                  className="toggle-nav"
                  onClick={() => {
                    closeSearchDropdown(false)
                    toggleMobileNav(!navIsOpen)
                  }}
                  aria-label="Toggle Navigation"
                  tabIndex={6}
                >
                  <CloseMenuIcon className="close-nav" />
                  <OpenMenuIcon className="open-nav" />
                </HamburgerOpenCloseIcon>
              </HamburgerOpenCloseContainer>
            </BurgerBox>
          ) : null}
        </>
      )}
    </UserContext.Consumer>
  )
}

export default HeaderIcons
