import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { Box, Flex } from "@rebass/grid/emotion"
import { useLocation } from "@reach/router"
import InfoIconRed from "../../assets/icons/info-icon-red.svg"
import Checkmark from "../../assets/icons/wild/checkmark.svg"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import CloseIcon from "../../assets/icons/wild/close-icon.svg"
import NotificationBar from "../account/notificationbar"
import { getUrlParameter } from "../../utils/general"
import { MAY_GIFTING_CART_DATA } from "../../constants"

const MessageBody = styled.div`
  background: transparent;
  ${({ mainPadding, isLogged }) =>
    mainPadding === false &&
    `
      background: unset;
      position: absolute;
      z-index: 10;
      top: 135px;
      @media (min-width: 768px) {
        top: ${isLogged ? `140px` : `130px`};
      }
      @media (min-width: 1024px) {
        top: ${isLogged ? `180px` : `150px`};
      }
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    `}
`

const MessageWrapper = styled.div`
  max-width: 1570px;
  ${(props) =>
    props.mainPadding
      ? "padding:1px 16px;"
      : "@media (min-width: 768px) {padding:0px 8px;}"};
  ${(props) =>
    props.mainPadding ? "" : " @media (min-width: 1024px) {padding:0px 25px;}"}
  margin-left: auto;
  margin-right: auto;
  .veryhidden {
    display: none !important;
  }
`

const NotificationCard = styled(Flex)`
  position: relative;
  align-items: center;
  min-height: 38px;
  z-index: 9;
  background: white;
  border-radius: 8px;
  padding: 0 15px;
  margin: 32px 0 7px 0;
  border: 1px solid rgb(31, 99, 96);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
  &.error {
    border: 1px solid #cc2500;
  }
  &.beige {
    background: rgb(220, 230, 221);
    border: none;
  }
  @media (min-width: 768px) {
    padding: 8px 32px;
    margin: 32px 0 -16px;
    min-height: 48px;
  }

  @media (min-width: 1024px) {
    margin: 40px 0 -8px;
    padding: 8px;
    min-height: 46px;
  }
`

const SessionMessage = styled(Flex)`
  flex-direction: row;
  width: 100%;
  color: ${theme.wild.color.master.base.black};
  font-size: 14px;
  line-height: 130%;
  font-weight: 300;
  letter-spacing: 0;
  padding: 0 15px 0 0;
  &.error {
    color: #e02b27;
  }
  &.success {
    color: #22202f;
  }
  &.info {
    color: #22202f;
  }
  .c-bold-date {
    font-weight: bold;
  }
  > span {
    font-family: ${theme.wild.font.dinamo.regular};
    font-weight: ${theme.weight.book};
  }
`

const CloseIconWrap = styled.button`
  margin-left: auto;
  display: block;
`

const IconWrap = styled(Box)`
  padding: 0 10px 0 0;
  @media (min-width: ${theme.mediaqueries.medium}) {
    padding: 0 8px 0 0;
  }
`

const SessionMessages = (props) => {
  const path = props.location
  const mainPadding = props.mainPadding
  const mainBgColor = props.bgColor || `Default`
  const isLogged = props.isLogged
  const { mageMessages } = props
  const [existingMessages, setExistingMessages] = useState(mageMessages)
  const location = useLocation()

  /* Clear message if route was changed */
  useEffect(() => {
    if (existingMessages) {
      setExistingMessages([])
    }
  }, [location.pathname])

  useEffect(() => {
    const readCookie = (name) => {
      const nameEQ = name + "="
      const ca = document.cookie.split(";")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length).replace(/\+/g, " ")
        }
      }
      return null
    }

    const readEncodedCookie = (name) => {
      let cookie_val = decodeURIComponent(readCookie(name))
      // eslint-disable-next-line no-console
      /*
       * May Gifting hack to replace error message for may gifting if uses per coupon was reached
       * document.cookie = 'mage-messages=[{"type":"error","text":"The discount code is not valid"}]'
       */
      if (
        typeof cookie_val === "string" &&
        cookie_val?.includes("The discount code is not valid") &&
        MAY_GIFTING_CART_DATA.find((item) =>
          item.codes?.includes(getUrlParameter("code"))
        )
      ) {
        cookie_val =
          '[{"type":"error","text":"Sorry, our travel kits went faster than aisle seats on a plane."}]'
      }
      // todo end hack, remove after May Gifting

      console.log("cookie_val ", cookie_val)
      /*
       *  cookie_val = decodeURIComponent("%5B%7B%22type%22%3A%22error%22%2C%22text%22%3A%22Customer+token+is+not+valid.%22%7D%2C%7B%22type%22%3A%22error%22%2C%22text%22%3A%22Customer+token+is+not+valid.%22%7D%2C%7B%22type%22%3A%22success%22%2C%22text%22%3A%22Success+Message%22%7D%5D")
       *  cookie_val = decodeURIComponent("%5B%7B%22type%22%3A%22success%22%2C%22text%22%3A%22You%20used%20coupon%20code%20%26quot%3BMOMS%26quot%3B.%22%7D%5D")
       *
       *       cookie_val = decodeURIComponent(
       *  "%5B%7B%22type%22%3A%22success%22%2C%22text%22%3A%22Your%20Hair%20Mineral%20Analysis%20order%20was%20placed%20successfully.%20If%20you%20have%20any%20questions%20please%20contact.%22%7D%5D"
       *  )need this hack because we are not encoding correctly on the BE NT2-4203
       * sometimes its a JSON string, sometimes it's encoded :/
       */

      const cookie_replaced = cookie_val
        .replace(/&quot;/g, "'")
        .replace(/&#039;/g, "'")
        //.replace(/\+/g, " ")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&#47;/g, "/")
      if (cookie_replaced !== "") {
        const msgs =
          typeof cookie_replaced === "string"
            ? JSON.parse(cookie_replaced)
            : cookie_replaced

        // Filter out duplicate messages before updating state
        const uniqueMessages = msgs?.filter(
          (msg, index, self) =>
            index ===
            self.findIndex((m) => m.type === msg.type && m.text === msg.text)
        )
        if (Array.isArray(msgs)) {
          setExistingMessages((prevMessages) => [
            ...prevMessages,
            ...uniqueMessages,
          ])
        } else {
          setExistingMessages((prevMessages) => [
            ...prevMessages,
            uniqueMessages,
          ])
        }
      }
      setTimeout(() => {
        let today = new Date()
        let expire = new Date()
        expire.setTime(today.getTime() + 3600000 * 24 * 7)
        document.cookie =
          "mage-messages=;expires=" + expire.toGMTString() + ";path=/;secure"
      }, 250)
    }

    readEncodedCookie("mage-messages")
  }, [path])

  const [hidden, setHidden] = useState(
    existingMessages?.length > 0 ? existingMessages.map((msg) => false) : []
  )

  const hider = (index) => {
    const newArray = [...hidden]
    newArray[index] = !newArray[index]
    setHidden(newArray)
  }

  const showMessages = (messages, pos) => {
    let addClass = pos !== 1 || (pos === 1 && messages.length === 1)
    if (!!messages && Array.isArray(messages)) {
      return messages.map((msg, i) => {
        if (msg?.type && msg?.text) {
          if (msg.text.toLowerCase().includes("subscription")) {
            return (
              <NotificationBar
                key={`item-${i}`}
                classes="beige with-margin full-width"
                message={{ message: msg.text, type: "info" }}
              />
            )
          }
          return (
            <NotificationCard
              className={`${hidden[i] ? "veryhidden" : "flex"} ${msg.type}  ${
                i === 0 && addClass ? "c-first" : ""
              }`}
              key={`item-${i}`}
            >
              <IconWrap px={1}>
                {msg.type === "error" ? <InfoIconRed /> : <Checkmark />}
              </IconWrap>
              <SessionMessage className={msg.type} key={`${msg.type}-${i}`}>
                <TextElement text={msg.text || msg} element="span" />
                <CloseIconWrap onClick={() => hider(i)}>
                  <CloseIcon />
                </CloseIconWrap>
              </SessionMessage>
            </NotificationCard>
          )
        } else {
          return null
        }
      })
    } else {
      return null
    }
  }

  const hasMessages = existingMessages?.filter((el) => !!el)?.length

  return hasMessages ? (
    <MessageBody
      mainPadding={mainPadding}
      isLogged={isLogged}
      className={`sessionMessage-${mainBgColor}`}
    >
      <MessageWrapper mainPadding={mainPadding}>
        {showMessages(existingMessages, 0)}
      </MessageWrapper>
    </MessageBody>
  ) : null
}

export default SessionMessages
