import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Box, Flex } from "@rebass/grid/emotion"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import PlusIcon from "../../../../assets/icons/nova/plus-small.svg"
import MinusIcon from "../../../../assets/icons/nova/minus.svg"

import NavigationShop from "./navigationshop"
import NavigationScience from "./navigationscience"

import {
  Nav,
  ArrowSection,
  InternalNavLink,
  ListItem,
  NavUl,
} from "./navigation.styled"

if (typeof window !== `undefined`) {
  // Polyfill for IE9+
  if (!window.Element.prototype.matches)
    window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector
  if (!window.Element.prototype.closest)
    window.Element.prototype.closest = function (selector) {
      var el = this
      while (el) {
        if (el.matches(selector)) {
          return el
        }
        el = el.parentElement
      }
    }
}

const addActiveItemClass = (el) => {
  if (el && el.classList && el.classList.contains("has-subnav")) {
    el.classList.add("hover")
  }
  handleExpand(el)
}

const removeActiveItemClass = (el, preventClick) => {
  const mouseLeftItem = hasHover(el)
  if (!!mouseLeftItem) {
    mouseLeftItem.classList.remove("hover")
  }
  if (preventClick) {
    el.preventDefault()
  }
  handleExpand(el)
}

const handleExpand = (el) => {
  if (el?.id) {
    ;[
      ...document.querySelectorAll("nav.navigation-variant [aria-expanded]"),
    ].forEach((a) => {
      const isHover = a?.parentElement?.classList?.contains("hover")
      a.ariaExpanded = isHover ? "true" : "false"
    })
  }
}

const onFocus = (el) => {
  handleExpand(el)
}

const onBlur = (el) => {
  handleExpand(el)
}

const removeAllActiveItemClass = (e) => {
  const hover = document.getElementsByClassName("hover")
  let i = 0
  for (; i < hover.length; i++) {
    hover[i].classList.remove("hover")
  }
}

const mouseClick = (item) => {
  removeAllActiveItemClass()
  addActiveItemClass(item)
}

const hasHover = (e) => e.target.closest(".has-subnav.hover")

const trackExpanded = (text) => {
  segmentEvent("Navigation Expanded", {
    module_location: "navigation",
    text,
  })
}

const trackClicked = (text, destination) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    type: "button",
    text,
    destination,
  })
}

const Navigation = ({ data, isMouseInHeader }) => {
  //const [hoveredItem, setHoveredItem] = useState(null)
  const [hideSubMenu, setHideSubMenu] = useState(false)

  useEffect(() => {
    if (hideSubMenu) {
      setHideSubMenu(false)
    }
  }, [hideSubMenu])

  const navLinks = data.map((item, index) => {
    return (
      <React.Fragment key={`navLink-${index}`}>
        {!item.isMobileOnly && (
          <ListItem
            id={item.name}
            key={`navitem-${index}`}
            className={item.children ? `has-subnav ` : ``}
            onMouseEnter={(e) => {
              if (!!item.children) {
                if (e.target.classList.contains("has-subnav")) {
                  trackExpanded(item.link.text)
                }
              }
              addActiveItemClass(e.currentTarget)
            }}
            onFocus={(e) => onFocus(e.currentTarget)}
            onBlur={(e) => onBlur(e.currentTarget)}
            onMouseLeave={(e) => removeActiveItemClass(e)}
            onClick={(e) => mouseClick(e.currentTarget)}
            data-linkname={item.name}
          >
            {/* we need href="" for ADA */}
            <InternalNavLink
              href={item.link.url}
              role="button"
              aria-expanded="false"
              className={item.children ? " has-children" : " no-children"}
              onClick={(e) => {
                removeActiveItemClass(e, true)
                if (item.link.url !== "#") {
                  trackClicked(item.link.text, item.link.url)
                  navigate(item.link.url)
                }
              }}
            >
              <Flex>
                <Box>
                  <TextElementNova
                    text={item.link.text}
                    className={`Body-20-Dia-Normal link-text ${item.name}`}
                  />
                </Box>
                {item.children ? (
                  <ArrowSection className={`plus`}>
                    <PlusIcon />
                  </ArrowSection>
                ) : null}
                {item.children ? (
                  <ArrowSection className={`minus`}>
                    <MinusIcon />
                  </ArrowSection>
                ) : null}
              </Flex>
            </InternalNavLink>
            {!!item.children && !hideSubMenu && (
              <Box>
                {item.name === "science" ? (
                  <NavigationScience data={data} />
                ) : (
                  <NavigationShop data={data} />
                )}
              </Box>
            )}
          </ListItem>
        )}
      </React.Fragment>
    )
  })

  return (
    <Nav className="navigation navigation-variant">
      <NavUl className="master-nav-ul">{navLinks}</NavUl>
    </Nav>
  )
}
export default React.memo(Navigation, (prevProps, nextProps) => true)
