export const SUBPUSH_PAGES = [
  "/nutrafol-core-for-women/3-month/",
  "/women-balance/3-month/",
  "/womens-vegan/3-month/",
  "/nutrafol-postpartum-for-women/3-month/",
  "/men/products/thinning-hair-growth-supplement/3-month/",
]

export const ACCOUNT_PAGES = [
  "/customer/account/create/",
  "/customer/account/login/",
  "/customer/account/forgotpassword/",
  "/customer/account/code/",
  "/customer/account/password/",
  "/customer/account/password/forgot/",
]

export const TT_PAGES = ["/tt/promo/"]

export const CAPTCHA_PAGES = [
  "login",
  "create",
  "password",
  "profile",
  "subscriptions",
  "edit-address",
  "edit",
  "privacy-policy",
  "privacy-request",
]

export const MAY_GIFTING_CART_DATA = [
  {
    codes: ["WOMGIFT", "WOMCHRNGIFT"],
    sku: "WOMGWP",
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/13Bd77Aiysign2P5x34x2L/7f00549f942278e3af603be662fcaa3a/women-cart-may-gwp-gift.jpeg",
  },
  {
    codes: ["BALGIFT", "BALCHRNGIFT"],
    sku: "BALGWP",
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/8IIxp0zj6AxzoTj9F3sr8/27f43c805537dbc457d7ca9d5317913f/balance-cart-may-gwp-gift.jpeg",
  },
  {
    codes: ["VEGGIFT", "VEGCHRNGIFT"],
    sku: "VEGGWP",
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1A6rd6whnvKvu0XmtTU1ik/43c93f69f6b961dfdae3bac9fed7fa2e/vegan-cart-may-gwp-gift.jpeg",
  },
  {
    codes: ["POSGIFT", "POSCHRNGIFT"],
    sku: "POSGWP",
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/1r3J5F6qbLw0raiDmwfnyM/59f42fe36e9b77ca805ac75930954f0c/postpartum-cart-may-gwp-gift.jpeg",
  },
  {
    codes: ["MENGIFT", "MENCHRNGIFT"],
    sku: "MENGWP",
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/23CiYhi0jhjXiXyGEBI5YP/e03706e48a58f86b76039203895b5282/men-cart-may-gwp-gift.jpeg",
  },
]
