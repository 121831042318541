import React, { useState, useRef, useContext } from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"

import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { renderRichTextDinamo } from "../../../../utils/contentful"
import UserContext from "../../../../context/UserContext"

const ribbonMap = {
  subpush: {
    copy: "Put your hair growth plan on autopilot with a Nutrafol<br class='show md:hidden'/> subscription and  <span class='font-dinamoBold'>see results in as little as 3 months.</span>",
    href: null,
  },
  serum: {
    copy: "Nutrafol Men + Hair Serum for visible results in as little as 3 months.",
    href: "/men/products/thinning-hair-supplements-and-serum/",
  },
  pick_your_booster: {
    copy: "{name}, celebrate with a free booster. <span class='font-dinamoBold'>Pick yours!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  pay_it_forward: {
    copy: "{name}, keep growing with $5 off. <span class='font-dinamoBold'>Apply now!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  upgrade_booster: {
    copy: "{name}, save 5% on renewals. <span class='font-dinamoBold'>Add your booster!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  free_gift: {
    copy: "{name}, celebrate with a gift. <span class='font-dinamoBold'>Pick yours now!</span>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  refer_a_friend: {
    copy: "{name}, <i>refer friends and save!</i>",
    href: "/account/rewards/?traffic_source=pencil_banner",
  },
  ribbon_products: {
    copy: "Shop All Products",
    href: "/men/products/",
  },
}

const RibbonLink = styled.a`
  flex: 1;
  cursor: pointer;
  width: 100%;
  font-family: ${theme.wild.font.dinamo.regular};
  font-size: 11px;
  line-height: 16px;
  padding: 8px 4px;

  .special-br-1 {
    display: none;
  }

  @media (min-width: 350px) and (max-width: 420px) {
    .special-br-1 {
      display: block;
    }
  }
  @media (min-width: 374px) {
    font-size: 12px;
  }
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media (min-width: 1280px) {
    padding: 8px 0;
  }
  .desktop-only {
    display: none;
    @media (min-width: 1024px) {
      display: inline-block;
    }
  }
  &.ribbon-ab-test-a {
    //background: green;
    padding: 16px 0 20px;
    @media (min-width: 1024px) {
      //padding: 18px 0 22px;
    }
  }
`

const RibbonContainer = styled.div`
  transition: none;

  background-color: ${theme.wild.color.men.brand.blue[40]};
  border-top: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  &.men-ribbon-promo {
    background-color: ${theme.wild.color.nova.brand.accent[80]};
  }
  width: 100%;
  z-index: 11;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 100px;
  @media (min-width: 768px) {
    height: 33px;
  }
  @media (min-width: 1024px) {
    box-shadow: none;
    box-sizing: border-box;
    position: relative;
    border-top-right-radius: 4px;
    border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    border-right: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    height: 35px;
    top: 0;
    em {
      display: inline;
    }

    margin-top: 0;

    // workaround for the promo yellow borders
    &.men-ribbon-promo {
      border-bottom: 1px solid ${theme.wild.color.nova.brand.accent[80]};
      border-top: 1px solid ${theme.wild.color.nova.brand.accent[80]};
      border-right: 1px solid ${theme.wild.color.nova.brand.accent[80]};
      position: relative;
    }
  }
  em.display-nicely {
    display: inline;
    @media (min-width: 431px) and (max-width: 1024px) {
      display: block;
    }
  }
`

const Ribbon = (props) => {
  const { contentfulHeaderRibbon, PROMO_VARIANT } = props
  const [isClosed, setIsClosed] = useState(false)
  const ribbonEl = useRef(null)
  const userState = useContext(UserContext)

  if (!contentfulHeaderRibbon) {
    return null
  }

  let ribbonHtml = renderRichTextDinamo(contentfulHeaderRibbon.ribbonText?.raw)
  let ribbonHref = contentfulHeaderRibbon.ribbonLink
  let isPromo = contentfulHeaderRibbon?.ispromo
  if (ribbonMap[PROMO_VARIANT]?.copy) {
    ribbonHtml = ribbonMap[PROMO_VARIANT].copy
    if (userState?.customer?.firstname) {
      ribbonHtml = ribbonHtml.replace("{name}", userState?.customer?.firstname)
    }
    ribbonHref = ribbonMap[PROMO_VARIANT].href
  }
  const getRibbonAttrs = () => {
    let obj = {
      destination: ribbonHref,
      text: ribbonHtml?.replace(/(<([^>]+)>)/gi, ""),
      type: "ribbon",
      module_location: "ribbon",
    }
    return obj
  }

  /*
   * const closeRibbon = () => {
   *   segmentEvent("Ribbon Closed", getRibbonAttrs())
   *   setIsClosed(true)
   *   if (props.isMouseInHeader) {
   *     props.isMouseInHeader.current = false
   *   }
   * }
   */

  const goTo = (e) => {
    segmentEvent("Marketing CTA Clicked", getRibbonAttrs())
    e.preventDefault()
    navigate(ribbonHref)
    return false
  }

  const content = (
    <TextElement
      element="p"
      text={ribbonHtml}
      className="body--regular text-center text-master-base-black"
      styles={{
        width: "100%",
        color: theme.wild.color.men.base.black,
      }}
    />
  )

  return (
    <RibbonContainer
      style={isClosed ? { display: "none" } : {}}
      className={`text-center ribbon-container men ${
        isPromo ? "men-ribbon-promo" : "men-ribbon-regular"
      }`}
    >
      {ribbonHref ? (
        <>
          <RibbonLink
            onClick={goTo}
            ref={ribbonEl}
            href={ribbonHref}
            id={`ribbon`}
            className={`body--regular text-master-base-black text-center`}
            dangerouslySetInnerHTML={{ __html: ribbonHtml }}
          ></RibbonLink>
          {/*NT2-17932 FNF Ribbon | Men's ribbon has an x to close
<CloseIconWrap onClick={closeRibbon}>*/}
          {/*  <CloseIcon />*/}
          {/*</CloseIconWrap>*/}
        </>
      ) : (
        <div className="w-full">{content}</div>
      )}
    </RibbonContainer>
  )
}

export default Ribbon
