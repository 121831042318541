import React from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/emotion"
import { Link } from "gatsby"

import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

const SiteSwitchWrap = styled(Flex)`
  flex-direction: row;
  background-color: #fff;
  min-height: 35px;
  align-items: center;
  border-top-left-radius: 2px;
  border-top: 1px solid #cdd2d1;
  overflow: hidden;
  border-left: 1px solid ${theme.wild.color.nova.transparency.black[20]};
`

const SiteSwitchBox = styled(Box)`
  text-align: center;
  box-sizing: content-box;
  border-bottom: 1px solid #fff;

  &:first-of-type {
    border-top-left-radius: 2px; /* Ensures it inherits the rounded corner */
  }
  &:not(:first-of-type) {
    &:hover {
      background-color: ${theme.wild.color.nova.base.solid[20]};
    }

    border-left: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  }

  &:last-of-type {
    border-right: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  }
  a {
    padding: 8px 0;
    display: block;
  }
`

const SiteSwitchLink = styled.a`
  justify-content: center;
`

const SiteSwitch = ({ className = `` }) => {
  const onRedirect = (destination, text) => {
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      destination,
      text,
    })
  }

  return (
    <Box className={`show-lg-up`}>
      <SiteSwitchWrap className={className}>
        <SiteSwitchBox width={[1 / 3]}>
          <TextElementNova
            text={`Women`}
            className={`CTA-10-Dia-Normal text-nova-base-black`}
          />
        </SiteSwitchBox>
        <SiteSwitchBox width={[1 / 3]}>
          <Link to={`/men/`} onClick={() => onRedirect(`/men/`, "Men")}>
            <TextElementNova
              text={`Men`}
              className={`CTA-10-Dia-Normal text-nova-transparency-black-70`}
            />
          </Link>
        </SiteSwitchBox>
        <SiteSwitchBox width={["calc(33.33% + 1px)"]}>
          <SiteSwitchLink
            href={`${process.env.GATSBY_MAGENTO_URL}skin/`}
            onClick={() => onRedirect(`/skin/`, "Skin")}
          >
            <TextElementNova
              text={`Skin`}
              className={`CTA-10-Dia-Normal text-nova-transparency-black-70`}
            />
          </SiteSwitchLink>
        </SiteSwitchBox>
      </SiteSwitchWrap>
    </Box>
  )
}

export default SiteSwitch
