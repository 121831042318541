export const GP1SUBSCRIBERSSEGMENT = "gp_1_subscribers_1_3_month"
export const GP1SUBSCRIBERSSEGMENTFREEGIFT =
  "gp_1_subscribers_1_3_month_free_gift"
export const FATHERSDAYRETENTIONPAIDADSAUDIENCE2024 =
  "2024_fathers_day_retention_paid_ads_audience"
export const GP1MONTHLYSUBSSEGMENT = "gp_1_monthly_subs"
export const GP1MONTHLYSUBSGREEGIFT = "gp_1_monthly_subs_free_gift"
export const GP1MONTHLYSUBSFREEGIFTOCT = "gp_1_monthly_subs_free_gift_october"
export const BFCM2024SEGMENT = "gp_1_subscribers_1_3_month"

//NT2-23232 FNF 2025 GWP Ugprade
export const FNFMASTER3MONTH = "fnf_25_upgrade_womens_monthly"
export const FNFMASTER6MONTH = "fnf_25_upgrade_womens_3_month"
export const FNFMENS6MONTH = "fnf_25_upgrade_mens"

export const FNFMASTER3MONTHACCEPTED = "fnf_25_upgrade_womens_monthly_gift"
export const FNFMASTER6MONTHACCEPTED = "fnf_25_upgrade_womens_3_month_gift"
export const FNFMENS6MONTHACCEPTED = "fnf_25_upgrade_mens_gift"
