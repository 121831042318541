import React, { useState, useEffect, useRef, useContext } from "react"
import classNames from "classnames"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import Logo from "./logo"
import HeaderIcons from "./headericons"
import Ribbon from "./ribbon"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import UserContext from "../../../../context/UserContext"
import { isProductPath } from "../../../../utils/general"
import ArrowLeft from "../../../../assets/icons/nova/arrow-left.svg"

const mobileFold = 1024

const HeaderWrap = styled.header`
  border: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  &[data-mousedown] {
    *:focus {
      outline: none;
    }
  }

  &.header-simple {
    border-radius: 2px;
  }

  @media (min-width: 1024px) {
    height: 64px;
  }
`
const HeaderContainer = styled.div`
  @media (min-width: 1024px) {
    padding: 0 25px;
  }
`
const ContentsContainer = styled.div`
  &.left-wrap,
  &.right-wrap {
    flex: 1;
  }

  .left-wrap {
    align-self: center;
  }

  .center-wrap {
    align-self: center;
  }

  .right-wrap {
    align-self: center;
  }

  .center-wrap {
    max-width: 141px;
    align-self: center;
  }
  @media (min-width: ${mobileFold}px) {
    height: 100%;
    .center-wrap {
      max-width: 156px;
    }
  }
`
const SimpleFlex = styled(Flex)`
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
`
const ReverseArrowLink = styled(Link)`
  span {
    display: inline-block;
    vertical-align: middle;
    width: fit-content;
    color: ${theme.wild.color.nova.base.black};
    border-bottom: 1px solid ${theme.wild.color.nova.base.black};
  }
`

const ArrowFlex = styled(Flex)`
  svg {
    width: 16px;
    height: 16px;
  }

  gap: 4px;
`

const SHOP = "Shop"
const CONTINUE = "Continue Shopping"

const HeaderSimpleMen = (props) => {
  const isMouseInHeader = useRef(false)
  const userState = useContext(UserContext)
  const [yVal, setYVal] = useState(0)

  const pathname = props.location && props.location.pathname

  let isMensFnf = false
  let isProductPage = false
  let isAccountPage = false
  let showCart = true
  let isIconVisibleOnRightSideOnly = false

  if (pathname) {
    isMensFnf = pathname?.indexOf("/men/fnf-") > -1
    showCart = pathname.indexOf("/checkout") === -1
    isProductPage = isProductPath(pathname)
    isAccountPage = pathname.indexOf("account") > -1
    isIconVisibleOnRightSideOnly =
      pathname.indexOf("/customer/account/login/") > -1 ||
      pathname.indexOf("/customer/account/create/") > -1
  }

  const copy = isAccountPage && !isIconVisibleOnRightSideOnly ? SHOP : CONTINUE

  useEffect(() => {
    const bodyElem = document.querySelectorAll("body")[0]

    const handleScroll = () => {
      if (!isMouseInHeader.current) {
        if (document.documentElement.scrollTop <= 85) {
          if (bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.remove("pagescrolled")
          }
        } else if (yVal > document.documentElement.scrollTop) {
          if (bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.remove("pagescrolled")
          }
        } else {
          if (!bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.add("pagescrolled")
          }
        }
        setYVal(document.documentElement.scrollTop)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [yVal, setYVal])

  return (
    <HeaderWrap
      data-mousedown
      className={classNames("header-wrap header-simple", {
        mousedown: props.mouseDown,
      })}
      onMouseEnter={() => (isMouseInHeader.current = true)}
      onMouseLeave={() => (isMouseInHeader.current = false)}
      onTouchEnd={() =>
        setTimeout(() => {
          isMouseInHeader.current = false
        })
      }
    >
      <HeaderContainer>
        <ContentsContainer className="wrap">
          <SimpleFlex flexWrap="wrap">
            <Box width={[3 / 10]} className="left-wrap">
              {!isMensFnf ? (
                <ReverseArrowLink
                  to={
                    userState?.site === "men" ? "/men/products/" : "/products/"
                  }
                  onClick={() => segmentEvent("Marketing CTA Clicked", {})}
                >
                  <TextElementNova
                    element="span"
                    children={
                      <ArrowFlex>
                        <Box mt={["-1px"]}>
                          <ArrowLeft />
                        </Box>
                        <Box>{SHOP}</Box>
                      </ArrowFlex>
                    }
                    className="CTA-10-Dia-Normal hide-md-up"
                  />
                  <TextElementNova
                    element="span"
                    children={
                      <ArrowFlex>
                        <Box
                          hidden={
                            isIconVisibleOnRightSideOnly || copy === CONTINUE
                          }
                        >
                          <ArrowLeft />
                        </Box>
                        <Box>{copy}</Box>
                      </ArrowFlex>
                    }
                    className="CTA-10-Dia-Normal show-md-up"
                  />
                </ReverseArrowLink>
              ) : null}
            </Box>
            <Box width={[4 / 10]} className="center-wrap">
              <Logo isSimple={true} site={userState.site} />
            </Box>
            <Box width={[3 / 10]} className="right-wrap">
              <Flex
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-end"
                style={{ marginTop: "1px" }}
              >
                <HeaderIcons
                  isSimple={true}
                  isIconVisibleOnRightSideOnly={isIconVisibleOnRightSideOnly}
                  isProductPage={isProductPage}
                  isAccountPage={isAccountPage}
                  showCart={showCart}
                />
              </Flex>
            </Box>
          </SimpleFlex>
        </ContentsContainer>
        {props.withRibbon && <Ribbon />}
      </HeaderContainer>
    </HeaderWrap>
  )
}

export default HeaderSimpleMen
