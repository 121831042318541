import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/apollo/client"

import { UserProvider } from "./src/context/UserContext"
import { CartProvider } from "./src/context/CartContext"
import { NavProvider } from "./src/context/NavContext"
import { SearchContextProvider } from "./src/components/search/searchcontext.js"
import { pathNameFormatter } from "./src/utils/pathNameFormatter.js"

import "./src/utils/global.scss"
import { readCookie } from "./src/utils/cookies"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { PDP_URLS } from "./src/constants"

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    <CartProvider>
      <NavProvider>
        <SearchContextProvider>
          <ApolloProvider client={client}>{element}</ApolloProvider>
        </SearchContextProvider>
      </NavProvider>
    </CartProvider>
  </UserProvider>
)

const undoNoScroll = () => {
  const bodyEl =
    typeof window !== "undefined" ? document.querySelector("body") : null
  if (typeof document !== `undefined` && bodyEl) {
    bodyEl.style.removeProperty("overflow")
    bodyEl.style.removeProperty("position")
    bodyEl.style.removeProperty("top")
    bodyEl.style.removeProperty("width")
  }
}

const currentThemeDataLayer = (location) => {
  let path = location
    ? location.pathname
    : typeof window !== `undefined`
    ? window.location.pathname
    : "/"
  if (path.indexOf("/men/") === 0) {
    return "mens"
  }
  return "master"
}

// close mobile and desktop navigation on route change
const closeAllNavigationsOnRouteChange = () => {
  /*
   * Deactivate & reset desktop nav
   * remove desktop active items
   */
  const desktopNavItems = document.querySelectorAll(".has-subnav")
  if (desktopNavItems) {
    for (let i = 0; i < desktopNavItems.length; i++) {
      desktopNavItems[i].classList.remove("hover")
    }
  }

  // if navigating away from PDP and scrolloptions are visible have to replace nav items
  const navigationItems = document.querySelector(".navigation")
  if (navigationItems) {
    navigationItems.style.visibility = ""
  }

  /*
   * Deactive & reset mobile nav
   * remove active mobile nav
   */
  const headerWrap = document.querySelectorAll(".header-wrap")[0]
  if (headerWrap) {
    if (headerWrap.classList.contains("nav-open")) {
      headerWrap.classList.remove("nav-open")
    }
  }

  // remove mobile active items
  const topNavItems = document.querySelectorAll(".nav-item")
  if (topNavItems) {
    for (let i = 0; i < topNavItems.length; i++) {
      topNavItems[i].classList.remove("active")
    }
  }

  // remove open menu classes
  const menuWrapper = document.querySelectorAll(".menu-wrapper")[0]
  if (menuWrapper) {
    menuWrapper.classList.remove("open")
  }

  // remove second level open class
  const header = document.querySelectorAll(".header-wrap")[0]
  if (header) {
    header.classList.remove("second-level-active")
  }

  /*
   * if on mobile, show zendesk/ada again
   * since it's hidden when menu is opened
   */
  const w = window.innerWidth
  const isZeLoaded = () => !!(window.zE && typeof window.zE.show === "function")
  const isAdaLoaded = () => !!window.adaEmbed
  if (w < 1024) {
    if (isZeLoaded()) {
      window.zE.show()
    } else if (isAdaLoaded()) {
      const adaBtn = document.getElementById("ada-button-frame")
      if (adaBtn) {
        adaBtn.style.display = "block"
      }
    }
  }

  // remove open modal class
  const body = document.querySelectorAll("body")[0]
  if (body) {
    body.classList.remove("no-scroll-wild")
    body.classList.remove("no-scroll")
  }

  undoNoScroll()
}

export function onPreRouteUpdate() {
  // console.log("onPreRouteUpdate")
  if (window.Yo) {
    // console.log("running Yo transition start")
    window.Yo.pubsub.publish({ topic: "rum/spa/transition/start", message: {} })
  }
  /*
   *  scrollPosition for hero gsap
   * window.sessionStorage.setItem("scrollPosition", window.scrollY)
   */
}

// quiz-ada proactive campaign
const launchQuizProactiveTest = (quizInfo, sameSession) => {
  if (window?.adaNutrafol?.run_quiz_proactive_test && window?.adaEmbed) {
    window.adaNutrafol.run_quiz_proactive_test(quizInfo, sameSession)
  }
}
// end quiz-ada proactive campaign

/**
 *
 * @param {string} pathname
 */
const trackProductPageVisits = ({ pathname = "" }) => {
  if (!PDP_URLS.includes(pathname)) return
  const pdpPageVisits = JSON.parse(localStorage.getItem("pdpVisits")) || {}

  if (pdpPageVisits[pathname]) {
    localStorage.setItem(
      "pdpVisits",
      JSON.stringify({
        ...pdpPageVisits,
        [pathname]: ++pdpPageVisits[pathname],
      })
    )
    return
  }

  localStorage.setItem(
    "pdpVisits",
    JSON.stringify({ ...pdpPageVisits, [pathname]: 1 })
  )
}

export function onRouteUpdate({ location, prevLocation }) {
  // NT2-8464: add dataLayer for nutrafolBrandTag
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    nutrafolBrandTag: currentThemeDataLayer(window.location),
  })

  closeAllNavigationsOnRouteChange()

  if (
    typeof window !== "undefined" &&
    typeof window.dataLayer !== "undefined"
  ) {
    window.dataLayer.push({ event: "gatsby-route-change" })
  }

  if (window.Yo && !!prevLocation) {
    // console.log("running Yo transition rendered")
    window.Yo.pubsub.publish({
      topic: "rum/spa/rendered",
      message: {
        viewType: "TRANSITION", // TRANSITION BC NOT FIRST LOAD
        pageType: pathNameFormatter(location.pathname),
      },
    })
  }

  // quiz-ada proactive campaign
  const quizInfo = JSON.parse(localStorage.getItem("quizResultsInfo"))
  const launchNow = JSON.parse(
    localStorage.getItem("launchCampaignRouteChange")
  )
  if (quizInfo?.recommended_id && launchNow) {
    const quizInfoTime = quizInfo?.quiz_completed_timestamp
    const sessionTime = sessionStorage.getItem("quizResultTime")
    if (quizInfoTime === sessionTime) {
      // same session launch now
      let sameSession = true
      launchQuizProactiveTest(sameSession)
    } else {
      // return session launch now
      let sameSession = false
      launchQuizProactiveTest(sameSession)
    }
  }
  // end quiz-ada proactive campaign

  // Track segment analytic page views
  window.analytics &&
    window.analytics.page(document.title, {
      business_line: "Hair",
      userAgent: navigator.userAgent,
    })

  // NT2-13177 Add evidon script to footer ( we have various footers so we need to inject upon onRouteUpdate)
  window.evidonHelper && window.evidonHelper.init(0)

  trackProductPageVisits(location)
}

// runs on initial client render "CLEAN"
export function onInitialClientRender() {
  // NT2-20964: add dataLayer for attentive popup
  window.dataLayer = window.dataLayer || []
  const oldPush = window.dataLayer.push
  window.dataLayer.push = function () {
    const events = [].slice.call(arguments, 0)
    // NT2-20964: add attentive segment events
    events.forEach((event) => {
      if (event?.event === "impression") {
        segmentEvent("Lead Capture Modal Viewed", {
          creative_id: event?.creative_id,
        })
      } else if (event?.event === "submitEmail") {
        segmentEvent("Attentive Email Marketing Opted In", {
          creative_id: event?.creative_id,
          email: event?.email,
          source: "attentive_popup",
        })
      } else if (event?.event === "expand") {
        segmentEvent("Lead Capture Expanded", {
          creative_id: event?.creative_id,
        })
      } else if (event?.event === "close") {
        segmentEvent("Lead Capture Closed", {
          creative_id: event?.creative_id,
        })
      }
    })

    return oldPush.apply(window.dataLayer, events)
  }
  // NT2-8464: add dataLayer for nutrafolBrandTag
  window.dataLayer.push({
    nutrafolBrandTag: currentThemeDataLayer(window.location),
  })

  // Create window Nutrafol object for globally accessable methods/properties
  window.nutrafol = window.nutrafol || {}

  // quiz-ada proactive campaign
  const quizInfo = JSON.parse(localStorage.getItem("quizResultsInfo"))
  const firstLoad = Date.now().toString()
  if (quizInfo?.recommended_id && firstLoad) {
    const quizInfoTime = quizInfo?.quiz_completed_timestamp
    const sessionTime = sessionStorage.getItem("quizResultTime")
    if (quizInfoTime === sessionTime) {
      // same session launch at 45 seconds
      let sameSession = true
      setTimeout(() => launchQuizProactiveTest(sameSession), 45000)
    } else {
      // return session launch at 30 seconds
      let sameSession = false
      setTimeout(() => launchQuizProactiveTest(sameSession), 30000)
    }
  }
  // end quiz-ada proactive campaign

  let location = window.location.pathname

  const initialYoViewClean = (num) => {
    if (window.Yo && location) {
      // console.log("running Yo clean rendered")
      window.Yo.pubsub.publish({
        topic: "rum/spa/rendered",
        message: {
          viewType: "CLEAN", // CLEAN BC FIRST LOAD ONLY
          pageType: pathNameFormatter(location),
        },
      })
    } else {
      if (num < 10) {
        setTimeout(
          () => {
            let newNum = num + 1
            initialYoViewClean(newNum)
          },
          500,
          num
        )
      }
    }
  }
  setTimeout(() => {
    initialYoViewClean(0)
  }, 1000)

  // send vegan metafield to adachat
  const checkIfVeganNotifyAda = () => {
    if (readCookie("isVegan") === "true" && !!window.adaEmbed) {
      window.adaEmbed.setMetaFields({
        vegan: true,
      })
    }
  }

  // FTI AB test - NT2-21956 - NT2-21955
  const ftiABTestSegmentEvent = (location) => {
    const ftiCookieValue = readCookie("from_the_inside_group")
    const ftiCookieValueMen = readCookie("men_from_the_inside_group")
    if (ftiCookieValue && location) {
      if (ftiCookieValue === "products" && location === "/products/") {
        segmentEvent("Experiment Viewed", {
          experiment_id: "12",
          experiment_name: "FTI Q125 | Women Consideration Ads Test V2",
          variant_name: "Control",
        })
      } else if (
        ftiCookieValue === "hair-growth-from-the-inside" &&
        location === "/hair-growth-from-the-inside/"
      ) {
        segmentEvent("Experiment Viewed", {
          experiment_id: "12",
          experiment_name: "FTI Q125 | Women Consideration Ads Test V2",
          variant_name: "Variant",
        })
      }
    } else if (ftiCookieValueMen && location) {
      if (ftiCookieValueMen === "products" && location === "/men/products/") {
        segmentEvent("Experiment Viewed", {
          experiment_id: "13",
          experiment_name: "FTI Q125 | Mens Consideration Ads Test V2",
          variant_name: "Control",
        })
      } else if (
        ftiCookieValueMen === "hair-growth-from-the-inside" &&
        location === "/men/hair-growth-from-the-inside/"
      ) {
        segmentEvent("Experiment Viewed", {
          experiment_id: "13",
          experiment_name: "FTI Q125 | Mens Consideration Ads Test V2",
          variant_name: "Variant",
        })
      }
    }
  }
  // END FTI AB test - NT2-21956 - NT2-21955

  const veganABTestSegmentEvent = (location) => {
    const veganCookieValue = readCookie("womenvegangrowth")
    if (veganCookieValue && location) {
      if (
        veganCookieValue === "nutrafol-core-for-women" &&
        location === "/nutrafol-core-for-women/"
      ) {
        segmentEvent("Experiment Viewed", {
          experiment_id: "11",
          experiment_name: "Growth Women Vegan LP Q125 | Meta Ads Test",
          variant_name: "Control",
        })
      } else if (
        veganCookieValue === "womenveganhairgrowth" &&
        location === "/womenveganhairgrowth/"
      ) {
        segmentEvent("Experiment Viewed", {
          experiment_id: "11",
          experiment_name: "Growth Women Vegan LP Q125 | Meta Ads Test",
          variant_name: "Variant",
        })
      }
    }
  }

  setTimeout(() => {
    // FTI AB test - NT2-21956
    const ftiTestPages = [
      "/products/",
      "/hair-growth-from-the-inside/",
      "/men/products/",
      "/men/hair-growth-from-the-inside/",
    ]
    if (ftiTestPages.includes(location)) {
      ftiABTestSegmentEvent(location)
    }
    const veganTestPages = [
      "/nutrafol-core-for-women/",
      "/womenveganhairgrowth/",
    ]
    if (veganTestPages.includes(location)) {
      veganABTestSegmentEvent(location)
    }
    // END FTI AB test - NT2-21956 - NT2-21955
    checkIfVeganNotifyAda()
  }, 3000)

  //NT2-17581 Hair site Switch navigation | user is not taken to top of the page on every click of the Switch links on top nav
  window.scrollTo(0, 0)
  //second attempt for back button
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 200)
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location
  const scrollToTopRoutes = [`/shedthesilence/`, "/tracking/"]
  //console.log('pathname', pathname)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    console.log("Force scroll to top of page.")
    window.history.scrollRestoration = "manual"
    window.scrollTo(0, 0)
  }
  return !location.hash
}

/*
 * NT2-15447 React Hydration Errors
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
 * https://github.com/gatsbyjs/gatsby/discussions/36232
 */
/*
 * export const replaceHydrateFunction = () => {
 *   return (element, container) => {
 *     const root = ReactDOM.createRoot(container)
 *     root.render(element)
 *   }
 * }
 */

// use segment.io analytics.js .ready
window.analytics &&
  window.analytics.ready &&
  window.analytics.ready(() => {
    //todo use segmentEvent() everywhere and lose middleware
    const addUserAgentDataToTrack = ({ payload, next }) => {
      if (!!payload && !!payload.obj && !!payload.obj.properties) {
        payload.obj.properties.userAgent = navigator.userAgent
      }
      next(payload)
    }

    try {
      const d = new Date()
      d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000)
      const expires = ";expires=" + d.toUTCString()
      document.cookie =
        "segment_anonymous_id=" +
        window.analytics.user().anonymousId() +
        expires +
        ";path=/;secure"
    } catch (error) {
      console.error("segment_anonymous_id failed", error)
    }

    window.analytics.addSourceMiddleware(addUserAgentDataToTrack)
  })

// VWO Segment Integration code
window.VWO = window.VWO || []

window.VWO.push([
  "onEventReceive",
  "vA",
  function (data) {
    // To fetch A/B test id
    var experimentId = data[1]
    // To fetch A/B test active variation name
    var variationId = data[2]
    // To get A/B test name
    var abTestName = window._vwo_exp[experimentId].name
    // To get A/B test active variation name
    var variationName = window._vwo_exp[experimentId].comb_n[variationId]

    if (
      typeof window._vwo_exp[experimentId].comb_n[variationId] !==
        "undefined" &&
      ["VISUAL_AB", "VISUAL", "SPLIT_URL", "SURVEY"].indexOf(
        window._vwo_exp[experimentId].type
      ) > -1
    ) {
      /*
       * Write your logic here to send the data at your end
       * console.log(window._vwo_exp[experimentId])
       * console.log("TestId ", experimentId)
       * console.log("TestName ", abTestName)
       * console.log("VarId ", variationId)
       * console.log("VarName ", variationName)
       */

      if (
        window.analytics &&
        experimentId &&
        abTestName &&
        variationId &&
        variationName
      ) {
        segmentEvent("Experiment Viewed", {
          experiment_id: experimentId,
          experiment_name: abTestName,
          variant_name: variationName,
        })
      }
    }
  },
])
