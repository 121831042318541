import React from "react"
import { Link } from "gatsby"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"

import Arrow from "../../../../assets/icons/nova/arrow-external.svg"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { NavSwitcherFlex } from "./header.styled"

const destinationMap = {
  women: "/",
  men: "/men/",
  skin: `${process.env.GATSBY_MAGENTO_URL}skin/`,
  quiz: `${process.env.GATSBY_MAGENTO_URL}quiz/`,
}

const destinationMapSegment = {
  women: "/",
  men: "/men/",
  skin: `/skin/`,
  quiz: `/quiz/`,
}

const NavSwitcher = ({
  type = "women",
  copy = `Switch to Nutrafol Women`,
  external = false,
}) => {
  const isQuiz = type === "quiz"
  const destination = destinationMap[type]
  const destinationSegment = destinationMapSegment[type]

  const onRedirect = () => {
    if (isQuiz) {
      segmentEvent("Marketing CTA Clicked", {
        destination: destinationSegment,
        module_location: "navigation",
        text: copy,
        type: "button",
      })
    } else {
      segmentEvent("Navigation Clicked", {
        module_location: "navigation",
        destination: destinationSegment,
        text: copy,
      })
    }
  }
  const cls = isQuiz ? "text-nova-base-white" : "text-nova-base-black"
  const clsLink = isQuiz ? "header-quiz-link" : ""
  return (
    <NavSwitcherFlex
      className={`h-full items-center flex-1  ${type}`}
      onClick={(e) => {
        e?.nativeEvent?.stopImmediatePropagation()
      }}
    >
      {external ? (
        <a
          href={destination}
          className={`flex ${clsLink}`}
          onClick={onRedirect}
        >
          <TextElementNova
            element="p"
            text={copy}
            className={`CTA-10-Dia-Normal ${cls}`}
          />
          <Arrow />
        </a>
      ) : (
        <Link className="flex" to={destination} onClick={onRedirect}>
          <TextElementNova
            element="p"
            text={copy}
            className={`CTA-10-Dia-Normal ${cls}`}
          />
          <Arrow />
        </Link>
      )}
    </NavSwitcherFlex>
  )
}

export default NavSwitcher
