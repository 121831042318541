import React from "react"
import { Box } from "@rebass/grid/emotion"

import { SectionLine } from "./section.styled"

const Section = ({
  maxWidth = 1600,
  px = [24, 24, 32, 48, 112],
  py = [40, 40, 48, 80],
  my = [40, 40, 48, 80],
  useMargin = false,
  className = "",
  children,
  ...restProps
}) => {
  return (
    <>
      <Box
        as="section"
        data-section // need attribute for use-sticky-navigation
        width="100%"
        maxWidth={maxWidth}
        mx="auto"
        py={!useMargin ? py : 0}
        my={useMargin ? my : 0}
        px={px}
        className={className + ` nova-section`}
        {...restProps}
      >
        {children}
      </Box>
      {restProps?.withBottomLine ? <SectionLine /> : null}
      {restProps?.withBottomLineDesktop ? (
        <SectionLine className={`show-md-up`} />
      ) : null}
    </>
  )
}

export default Section
