import React, { useState, useRef } from "react"
import { navigate } from "gatsby"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { RibbonLink, RibbonContainerSimple } from "./ribbon.styled"

const ribbonMap = {
  core_tt_promo: {
    copy: "Take $10 OFF in cart with code <span class='font-dinamoBold'>TIKTOK</span>",
  },
}

const RibbonSimple = (props) => {
  const { PROMO_VARIANT = "core_tt_promo" } = props

  const [isClosed] = useState(false)
  const ribbonEl = useRef(null)

  let ribbonHtml = null
  let ribbonHref = null
  if (ribbonMap[PROMO_VARIANT]?.copy) {
    ribbonHtml = ribbonMap[PROMO_VARIANT].copy
    ribbonHref = null
  } else {
    return null
  }

  const getRibbonAttrs = () => {
    let obj = {
      destination: ribbonHref,
      text: ribbonHtml?.replace(/(<([^>]+)>)/gi, ""),
      type: "ribbon",
      module_location: "ribbon",
    }
    return obj
  }

  const goTo = (e) => {
    segmentEvent("Marketing CTA Clicked", getRibbonAttrs())
    e.preventDefault()
    navigate(ribbonHref)
    return false
  }

  const content = (
    <TextElementNova
      element="p"
      text={ribbonHtml}
      className="Body-20-Dia-Normal text-center text-nova-base-black"
      styles={{
        width: "100%",
        color: theme.wild.color.nova.base.black,
      }}
    />
  )

  return (
    <RibbonContainerSimple
      style={!isClosed ? { backgroundColor: "#E8FAF7" } : { display: "none" }}
      className="text-center"
    >
      {ribbonHref ? (
        <>
          <RibbonLink
            onClick={goTo}
            ref={ribbonEl}
            href={ribbonHref}
            id={`ribbon`}
            className={`Body-20-Dia-Normal text-nova-base-black text-center`}
            dangerouslySetInnerHTML={{ __html: ribbonHtml }}
          ></RibbonLink>
        </>
      ) : (
        <div className="w-full">{content}</div>
      )}
    </RibbonContainerSimple>
  )
}

export default RibbonSimple
