/**
 * Specific to search will re-organize these
 * methods if we have more use cases, plus keeping
 * these centralized to reduce conflicts
 */

const matchBrackets = /[^{{]+(?=}})/g

// Pdp Path To SKU Map
const pdpPathToSku = {
  "/nutrafol-core-for-women/": "WOM",
  "/womens-vegan/": "VEG",
  "/women-balance/": "BAL",
  "/nutrafol-postpartum-for-women/": "POS",
  "/men/products/thinning-hair-growth-supplement/": "MENPRO1",
  "/men/products/hairline-thinning-dht-blocker-supplement/": "DHTINHIBITOR",
  "/men/products/thinning-hair-supplements-and-serum/": "MHAIRSERUMSML",
  "/de-stress-hair-growth-duo/": "STESSADAPTOGEN",
  "/gut-microbiome-hair-growth-duo/": "MICROBIOME",
  "/strengthening-hair-growth-duo/": "CINFUSION",
  "/hormone-support-hair-growth-duo/": "HORMONESUPPORT",
  "/toxin-cleanse-hair-growth-duo/": "TOXINCLEANSE",
  "/energy-hair-growth-duo/": "BENERGIZED",
  "/scalp/cleanser-shampoo/": "RPURIFIER",
  "/scalp/products/conditioner/": "SDEFENDER",
  "/scalp/hair-serum/": "HAIRSERUMSML",
  "/scalp/exfoliator-mask/": "BBLOCKER",
}

/**
 * We must use all skus from contentful instead of managing another
 * set (one source of truth)
 *
 * @param {[]} products
 * @returns {{}}
 */
export const reformatProducts = (products) => {
  const categories = {}

  products.map((product) => {
    categories[product?.title.split(" ")[0].toLowerCase()] = [
      ...product?.categories.map((cat) => cat?.sku),
    ]
  })

  return categories
}

/**
 * @param {{}} customer
 * @param {[]} subscriptions
 * @returns {""}
 */
export const getSubscriptionState = (subscriptionsAndOrders) => {
  const subscriptions = subscriptionsAndOrders?.subscriptions
  const customer = subscriptionsAndOrders?.customer

  if (subscriptions?.length) {
    const hasActiveSubscription = subscriptions.find(
      (sub) => sub.status === "active"
    )
    return hasActiveSubscription ? "subscriber" : "previously subscribed"
  }

  if (customer?.orders?.items?.length) {
    return "one-time purchase"
  }

  return "default"
}

/**
 * @param {{}} customer
 * @param {[]} subscriptions
 * @returns {{canceled: *[], active: *[]}}
 */
export const reformatSubscriptions = ({ subscriptions, customer }) => {
  const userSubscriptionsBySku = {
    active: [],
    canceled: [],
    single: [],
    payment_failed: [],
  }

  subscriptions?.forEach((sub) => {
    // get skus from growth plan data
    const product_growth_plan_data = Object.values(
      JSON.parse(sub.product_growth_plan_data)
    )?.[0]
    const subSkus = Object.keys(product_growth_plan_data?.child_products)

    if (userSubscriptionsBySku[sub.status]) {
      subSkus.forEach((sku) => {
        userSubscriptionsBySku[sub.status].push(sku)
      })
    }
  })

  customer?.orders?.items?.forEach((order) => {
    order?.items?.forEach((item) => {
      const sku = item?.product_sku
      const billing_interval = item?.billing_interval
      if (billing_interval === "") {
        userSubscriptionsBySku.single.push(sku)
      }
    })
  })

  return userSubscriptionsBySku
}

/**
 * Zendesk specific
 *
 * Input:
 * {{button_this_is_the_label_/quiz/}}
 *
 * Output:
 * ["this is the label", "/quiz/"]
 *
 * @param {string} button
 * @returns {[string, string]}
 */
export const getFormattedButton = (button) => {
  const reformatButton = button.split("_")
  const lastElement = reformatButton.length - 1
  const url = reformatButton[lastElement]
  const text = reformatButton.slice(1, lastElement).join(" ")

  return [text, url]
}

/**
 * Input:
 * {{table}}
 *
 * Output:
 * table
 *
 * @param {string} body
 * @returns {*}
 */
export const stripZendeskSpecialStrings = (body) => {
  // Hacky solution for now, maybe we can control this with a package or better regex
  const reformatted = body
    .split(/<[^>]+>/g)
    .filter((i) => i.length > 3)
    .map((i) => `<p>${i}</p>`)
    .join("")
    .replace(matchBrackets, "")
  return reformatted?.split("<p>{{}}</p>")?.join("")
}

/**
 *
 * @param {string} body
 * @returns {*}
 */
export const getInteractiveElements = (body) => body?.match(matchBrackets)

/**
 *
 * @param {[]} categories
 * @param {[]} options
 * @returns {*[]}
 */
export const filterCategoriesByType = (categories, option) => {
  return categories.filter((item) => option.includes(item[0]))
}

/**
 *
 * @param {[]} items
 * @param {{}} subscriptions
 * @returns {*[]}
 */
export const getFilteredCategories = (categories, subscriptions) => {
  const categoryTypes = {
    core: "Hair Growth Supplements",
    booster: "Wellness Boosters",
    scalp: "Scalp Care",
  }
  const options = {
    subscriber: [categoryTypes.booster, categoryTypes.scalp],
    skinSubscriber: [categoryTypes.core, categoryTypes.booster],
    cancelled: [categoryTypes.core, categoryTypes.scalp],
    default: [categoryTypes.core],
  }

  const categoryArray = Object.entries(categories)
  if (subscriptions?.active?.length) {
    const isSkinSubscriber = subscriptions.active.some((item) =>
      item.includes("SKIN")
    )
    return filterCategoriesByType(
      categoryArray,
      isSkinSubscriber ? options.skinSubscriber : options.subscriber
    )
  }
  if (
    subscriptions?.canceled?.length ||
    subscriptions?.payment_failed?.length ||
    subscriptions?.single?.length
  ) {
    return filterCategoriesByType(categoryArray, options.cancelled)
  }
  return filterCategoriesByType(categoryArray, options.default)
}

/**
 *
 * @returns {*[]}
 */
export const getPdpVisitSkus = () => {
  const pdpVisits = JSON.parse(localStorage.getItem("pdpVisits"))
  if (pdpVisits) {
    const pdpVisitsSkus = Object.entries(pdpVisits)
      ? Object.entries(pdpVisits)
          .sort((a, b) => b[1] - a[1])
          .map(([path]) => pdpPathToSku[path])
          .filter((sku) => sku)
      : []
    return pdpVisitsSkus
  }
  return []
}

/**
 *
 * @returns {*[]}
 */
export const getQuizResultsInfoSkus = () => {
  const quizResultsInfo = JSON.parse(localStorage.getItem("quizResultsInfo"))
  const quizResultsInfoSkus = quizResultsInfo?.skus ? quizResultsInfo.skus : []
  return quizResultsInfoSkus
}

/**
 *
 * @param {[]} products
 * @param {[]} skus
 * @returns {*[]}
 */
export const sortProducts = (products = [], purchasedSkus = []) => {
  const sortedProducts = products.sort((a, b) => a.orderQty - b.orderQty)
  const sortingSkus = [
    ...purchasedSkus,
    ...getPdpVisitSkus(),
    ...getQuizResultsInfoSkus(),
  ]
  return sortedProducts.sort((a, b) => {
    for (const sku of sortingSkus) {
      const skuBase = sku.split("-")[0]
      if (a.sku.includes(skuBase)) {
        return -1
      } else if (b.sku.includes(skuBase)) {
        return 1
      }
    }
    return 0
  })
}

/**
 *
 * @param {[]} items
 * @param {{}} subscriptions
 * @returns {*[]}
 */
export const getCanceledOrders = (items, subscriptions) => {
  const skus = reformatProducts(items)
  const canceledProduct = []

  Object.keys(skus).forEach((category) => {
    skus[category].forEach((product) => {
      if (subscriptions?.canceled.includes(product)) {
        canceledProduct.push(product)
      }
    })
  })

  return canceledProduct
}

/**
 *
 * @param {[]} contentfulData
 * @param {[]} algoliaData
 * @returns {*}
 */
export const matchAlgoliaToContentful = (contentfulData, algoliaData) => {
  const products = {}
  contentfulData?.nodes.map((product) => (products[product?.sku] = product))

  return algoliaData?.map((product) => {
    if (products[product.sku]) {
      return { ...product, ...products[product?.sku] }
    }
  })
}
