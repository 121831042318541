// WeveHelped Module
import CheckmarkIcon from "../../assets/icons/men/growthplan-checkmark.svg"
import LeafIcon from "../../assets/icons/men/growthplan-leaf.svg"
import BeakerIcon from "../../assets/icons/men/growthplan-beaker.svg"

// Expert Module
import Doctor from "../../assets/icons/nova/doctor.svg"
import Wave from "../../assets/icons/nova/wave.svg"
import Pill from "../../assets/icons/wild/icon-pill.svg"
import { GPPLUS1, GPPLUS2, GPPLUS3 } from "../../constants"

export const data = {
  seo: {
    title: "GrowthPlan+",
    description:
      "The Nutrafol GrowthPlan+ builds a customized plan to support your hair health.",
  },

  touchPoints: {
    head: `GROWTHPLAN+`,
    title: `Get the GrowthPlan+ Guarantee.`,
    subtitle: `The premium plan you can count on, or your money back.`,
    link: `/men/growthplanplus/`,
    label: `Learn More`,
  },

  banner: {
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/69cyR9LbOhK5rIGJXBltKy/bf9c8ccfba18e160d7292418bdeb7d3e/growthplanplus-hero-men.jpg?fl=progressive",
    imageTablet:
      "https://images.ctfassets.net/0rbfqd9c4jdo/6jp7I2CASdsz0ZmvFky4ND/5133382966afe089a0ea0df2b7111853/growthplanplus-hero-men-tablet.jpg?fl=progressive",
    imageMobile:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4vY9Bn2BqbulWCy1LtQiqK/ec87ff082277e1e5d4f6e066eb452eb2/growthplanplus-hero-men-mobile.jpg?fl=progressive",
    title: "The all-inclusive hair health plan: GrowthPlan+",
    copy: "Let our expert naturopathic doctors care for your hair and give you peace of mind with a money-back, results guarantee.*",
    button: {
      label: "Schedule Your Consult ",
      url: "/men/growthplanplus/scheduling/",
    },
  },
  weveHelped: {
    title: `We’ve helped ${process.env.GATSBY_NUTRAFOL_GROWING_NUMBER} million people grow, let us help you.`,
    items: [
      {
        icon: CheckmarkIcon,
        text: "#1 dermatologist-recommended hair growth supplement brand<sup>†</sup>",
      },
      {
        icon: LeafIcon,
        text: "100% drug-free <br class='hidden lg:block' />ingredients",
      },
      {
        icon: BeakerIcon,
        text: "Physician-formulated & clinically tested",
      },
    ],
  },

  expert: {
    title:
      "Expert guidance <span class='whitespace-nowrap font-gotham'>rooted in data.</span>",
    ctaButton: {
      label: "Schedule Your Consult",
      path: "",
    },
    items: [
      {
        heading:
          "Schedule your hair wellness<br class='block sm:hidden' /> consultation <span class='whitespace-nowrap font-dinamoBold'>& order your kit.</span>",
        copy: "Connect 1:1 with one of our licensed naturopathic doctors. We’ll ship your Hair Mineral Analysis. ",
        icon: Doctor,
        modalData: {
          button: {
            label: "Learn More",
          },
        },
      },
      {
        heading:
          "Receive your kit<br class='block sm:hidden md:block' /> and send back your hair sample.",
        copy: "An in-depth lab test of a few small snips of hair will help your naturopathic doctor determine what it needs for healthier growth.",
        icon: Wave,
        modalData: {
          button: {
            label: "Learn More",
          },
        },
      },
      {
        heading: "Receive results<br /> and your plan.",
        copy: "Your naturopathic doctor will walk you through your results and recommend adjustments to your plan based on your results.",
        icon: Pill,
        modalData: {
          button: {
            label: "Learn More",
          },
        },
      },
    ],
    quote: {
      copy: `“Dr. Kara was extremely knowledgeable. I appreciate that she took the time to explain through everything. I feel as though she is making the proper recommendation. She followed up and sent me everything as discussed.”`,
      reference: "— GrowthPlan+ customer on working with Dr. Kara Noe, ND",
    },
  },

  growthplanexample: {
    heading: "Sample GrowthPlan+",
    subheading: "What your GrowthPlan+ could look like.",
    disclaimer:
      "GrowthPlan+ product and gift details are just an example and meant to be representative of a typical GrowthPlan+ experience. Your ND will work with you to determine the right plan for you.",
    items: [
      {
        title: "Nutrafol Hair Growth Nutraceutical + Hair Wellness Boosters",
        copy: "Your naturopathic doctor will select your Hair Growth Nutraceutical—as well as any targeted boosters to provide additional support for specific root causes that may be impacting your hair health.",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/0IUUABaHoAG7Xe3q2YZZB/25e093c1e3cdcfb8be7c84d9f524f4f3/samplegrowthplan-hgn.jpg?fl=progressive",
      },
      {
        title: "Shampoo <br class='hidden lg:block' />& Conditioner ",
        copy: "During your consultation, your naturopathic doctor will assess your hair care routine and introduce Nutrafol’s shampoo and conditioner to support your hair health regimen.",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/6Cy9Cxr11g6ztsKDyOdVBN/5efe1bfe0f81cafb66c590714d8f7398/samplegrowthplan-scalp.jpg?fl=progressive",
      },
      {
        title: "Scalp Care",
        copy: "To enhance your GrowthPlan+, scalp care products designed to address issues like hair breakage, product build-up, or a dry, itchy scalp may be recommended.",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/2WPskJx1tHOKauFmgad77Y/f946a90289d0b29f66f8d29367877a26/samplegrowthplan-serum.jpg?fl=progressive",
      },
      {
        title: "Supportive Gifts",
        copy: "Following your Hair Mineral Analysis consultation, your naturopathic doctor will send you a gift to help you manage your primary root cause of thinning.",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/6dXImONXvSaNxf0C0pLOz6/b023c05e78a78ef26ca4f28cd6c48a04/samplegrowthplan-gift.jpg?fl=progressive",
      },
      {
        title: "Personalized Nutrition <br class='hidden lg:block' />Plan",
        copy: "Your naturopathic doctor will review your current diet and create a customized meal plan to ensure you receive essential nutrients for overall well-being.",
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/6jtg1qKLdSJTsVtqWfyCGa/ef8bcbaf42560f864d083586bbcc345c/samplegrowthplan-plan.jpg?fl=progressive",
      },
    ],
  },

  whattoexpect: {
    heading: "What to expect.",
    button: {
      label: "Schedule Your Consult",
      path: "/men/growthplanplus/scheduling/",
    },
    steps: [
      {
        iconBox: {
          step: 1,
          icon: Doctor,
        },
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/3Jm1bSw0bXOUlVvjpWWO93/e490be7b5917479de27c42bfd4cd087b/whattoexpect-order.jpg?fl=progressive",
        heading: "Schedule your hair wellness consult & order your kit",
        items: [
          "Schedule a phone call with one of our licensed naturopathic doctors.",
          "Order your starter kit that includes everything you need for your hair sample collection.",
          "Discuss your hair, health, and daily life with your naturopathic doctor.",
        ],
      },
      {
        iconBox: {
          step: 2,
          icon: Wave,
        },
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/58AenKrH1QTagKh3jgKGqt/5768645c8efe5768c71f396e915fedea/whattoexpect-send.jpg?fl=progressive",
        heading: "Receive your kit & send back your hair sample",
        items: [
          "Snip and ship a small hair sample with the kit we’ll provide to you.",
          "Your hair sample will be sent to the lab to be analyzed for 27 minerals, metals, and nutrient ratios that impact hair wellness using state-of-the-art ICP Mass Spectrometry technology.",
          "Results shed light on how your body responds to stress, environment, hormones, metabolism, and nutrition.",
        ],
      },
      {
        iconBox: {
          step: 3,
          icon: Pill,
        },
        image:
          "https://images.ctfassets.net/0rbfqd9c4jdo/4CcAHmyFlLkMuWCeyI69LH/e7811e9d276908b398afc41e5ebc38b7/whattoexpect-receive.jpg?fl=progressive",
        heading: "Receive results & your plan ",
        items: [
          "Customized report of your Hair Mineral Analysis lab test.",
          "Personalized lifestyle and nutrition advice based on your results.",
          "Nutrafol Hair Growth Nutraceuticals and any Hair Wellness Boosters and Scalp Care that your ND recommends, delivered monthly.",
        ],
      },
    ],
  },

  whatsincluded: {
    heading: "What’s included? ",
    subheading: "$299/month gives you everything you need to grow.",
    items: [
      {
        title: "Unlimited monthly products",
        copyItems: [
          "Your naturopathic doctor will recommend the right Hair Growth Nutraceutical for you, any boosters for additional support, as well as all the Scalp Care that might benefit you.",
          "($305 value, based on the average GrowthPlan+ customers product plan) ",
        ],
      },
      {
        title: "Unlimited access to naturopathic doctors",
        copyItems: [
          "Schedule consultations with your naturopathic doctor when you want and as often as you want, or text our team of naturopathic doctors for quick questions and advice.",
          "(Average cost of a single naturopathic doctor consult $360, based on national averages.)",
        ],
      },
      {
        title: "Nutrition support",
        copyItems: [
          "Receive customized nutrition plans curated by your naturopathic doctor with recipes and shopping lists to support your whole-body health. ",
        ],
      },
      {
        title: "Priority customer support",
        copyItems: [
          "Call or text our customer experience team any time using the number reserved exclusively for GrowthPlan+ customers.",
        ],
      },
      {
        title: "Hair Mineral Analysis ",
        copyItems: [
          "The Hair Mineral Analysis sheds light on how your body responds to stress, environment, hormones, metabolism, and nutrition. Using state-of-the-art ICP Mass Spectrometry technology, we’ll look for 27 essential minerals and metals in your hair and important nutrient ratios.",
          "(Analysis and Results Consultation $200 Value)",
        ],
      },
      {
        title: "Headspace membership",
        copyItems: [
          "Get all access to the leading platform of science-backed meditation tools that support your mental well-being and help you along your hair growth journey.",
          "($12.99/month value) ",
        ],
      },
      {
        title: "Supportive gifts",
        copyItems: [
          "Following your consultation, your naturopathic doctor will send you a gift to help you manage your primary root cause of thinning.",
        ],
      },
      {
        title: "6-month results money back guarantee*",
        copyItems: [
          "In your initial consultation your naturopathic doctor (ND) will help you determine if GrowthPlan+ is right for you. If it is, your ND will create a personalized plan that sets you up for success and regularly check-in to adjust your plan as needed. If, after 6-months of following this expert guidance, you feel you have not seen results, you may be eligible for a full refund. If for some reason, you and your ND determine that GrowthPlan+ isn’t the best path for you, they’ll help you connect with a dermatologist near you. Learn more. ",
        ],
      },
    ],
  },

  doctor: {
    doc: {
      name: "Dr. Kali Olsen, ND, L.Ac, MS",
      copy: "Uses her varied educational background to support whole-body wellness including healthy hair growth and practices with a focus in holistic skin support and graceful aging.",
      image:
        "https://images.ctfassets.net/0rbfqd9c4jdo/28Zmis1F2IQLPFeSEwLwEY/51c40173581490e87d90d45dc3d3d30b/expert-doctor-klughers.jpg?fl=progressive",
    },
    heading:
      "Our naturopathic doctors are hair health experts and  will meet with you to curate a plan just for you.",
    subHeading:
      "With their guidance, access to diagnostics, and the power of Nutrafol’s Hair Growth Nutraceuticals, you’ll achieve the growth you’ve been searching for, all backed by our GrowthPlan+ Guarantee. ",
    questions: [
      {
        title: "What is a naturopathic doctor? ",
        answer:
          "Naturopathic doctors (NDs) are licensed healthcare professionals specializing in holistic medicine.",
        disclaimer:
          "Disclaimer: THE NATUROPATHIC DOCTORS AT NUTRAFOL DO NOT INTEND TO DIAGNOSE, TREAT, OR MANAGE ANY MEDICAL CONDITIONS, INCLUDING ALL HAIR-RELATED CONDITIONS.",
      },
    ],
  },

  scheduleCta: {
    heading:
      "GrowthPlan+ is the first and only, all-inclusive, naturopathic doctor-led, hair health plan with a results guarantee.*",
    button: {
      label: "Schedule Your Consult",
      path: "/men/growthplanplus/scheduling/",
    },
  },

  ctaBanner: {
    images: {
      image:
        "https://images.ctfassets.net/0rbfqd9c4jdo/7KbRDAttO9jnK7XgysOa5y/bcc792f5563954a7af254e5b1344ec4f/gp-cta-desktop.jpg?fl=progressive",
      imageMobile:
        "https://images.ctfassets.net/0rbfqd9c4jdo/7yTpD78p5BrKrBWeUR9yAr/67377e4bff039d80c0e383f2f7a40f16/gp-cta-mobile.jpg?fl=progressive",
      imageTablet:
        "https://images.ctfassets.net/0rbfqd9c4jdo/4QzBJ2Wl1w94mkaFb81UDz/1aca5de2fcf50dcec7f7f96e3804094e/ct-gpa-tablet.jpg?fl=progressive",
    },
    title: "Get Your GrowthPlan+",
    ctaButton: {
      label: "Schedule Your Consult",
      url: "/men/growthplanplus/scheduling/",
    },
    info: "A GrowthPlan+ subscription is $299/month. You will book your first consultation now and will be charged $299 + applicable taxes when you complete checkout. In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you.",
    learnMoreButton: {
      label: "*Learn more about our GrowthPlan+ Guarantee.",
      url: "/men/growthplanplus/scheduling/",
    },
  },

  questions: [
    {
      title: "How is Nutrafol different from other hair growth products?",
      answer:
        "We’re a company that recognizes the importance of meeting you where you are in life. Our drug-free formulas are different from other hair growth products because they’re formulated for your bio-specific needs. We are a multi-targeted approach that successfully addresses key root causes of hair thinning to support visibly longer, stronger hair growth with vitamins, minerals, and natural ingredients.",
    },
    {
      title: "How will my progress be measured?",
      answer:
        "You will work closely with a naturopathic doctor who, along with recommending a customized product plan to you, will also check in at 3 and 6 months touch points with photo tracking and in-depth evaluations of your progress.",
    },
    {
      title:
        "What does a GrowthPlan+ initial consultation with a naturopathic doctor entail?",
      answer:
        "Your consultation with our naturopathic doctor will include a detailed discussion regarding your hair concerns—what you’ve been experiencing, and for how long; how you’ve addressed your hair concerns in the past; and how your hair struggles have impacted your life. Next, you’ll explore lifestyle factors—such as diet, daily stressors, quality of sleep, digestive function, and overall health. With this information, your ND will be able to recommend the Nutrafol products that are the best fit for you, help you to understand how ingredients are supporting your hair health, offer you lifestyle recommendations and important tools to incorporate into your daily routine, and answer any questions about Nutrafol, your plan, or your hair.",
    },
    {
      title: "What products are included?",
      answer:
        "Product selection is dependent on your specific needs and will be determined by the naturopathic doctor post-consultation. This will include the Hair Growth Nutraceutical that is right for you, as well as Hair Wellness Boosters or scalp products that your ND recommends for you.",
    },
    {
      title: "What is Hair Mineral Analysis?",
      answer:
        "A Hair Mineral Analysis (HMA) is a kit that allows you to send a hair sample to be analyzed by our lab partner. The results help provide your ND with information and data about your hair health. It is used to help determine key root causes of hair thinning that are specific to you so that we can build out and refine a personalized plan that best supports your hair health.",
    },
    {
      title: "What does GrowthPlan+ “Unlimited Access to Experts” entail?",
      answer:
        "At any point throughout your journey, you can schedule a GrowthPlan+ Follow-up Consultation with your Nutrafol naturopathic doctor. We also provide you a phone number where you can text brief questions and receive a response from the Nutrafol naturopathic doctor team within 1-2 business days.",
    },
    {
      title: "How do I take progress photos?",
      answer:
        "Once you complete your enrollment, we’ll reach out with instructions to assist you in texting or emailing your photos directly to our team. Once we have received your 5 photos, your naturopathic doctor will review and we will begin processing your product order when we have their final recommendation.",
    },
    {
      title:
        "We request that you please submit your photo(s) following these requirements:",
      answer:
        "- Keep your hairstyle, lighting, and background consistent in your progress photos.<br />- Ensure you are in a well-lit spot<br />- Document multiple angles (Front view, temples on both sides, top of head and crown)",
    },
  ],

  ref: {
    button: {
      url: "/men/growthplanplus/scheduling/",
      label: "*Learn more about our GrowthPlan+ Guarantee",
    },
    title:
      "In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you. ",
    copy: "†According to IQVIA ProVoice survey for 12 months ending March 31, 2024.",
  },

  sixMonthGuarantee: {
    disclaimer:
      "In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you.",
    disclaimer2:
      "†According to IQVIA ProVoice survey for 12 months ending March 31, 2024.",
    copy: "*Learn more about our GrowthPlan+ Guarantee",
    modalData: {
      title: "GrowthPlan+ Guarantee Terms & Conditions",
      items: [
        {
          title: "item-1",
          text: GPPLUS1,
        },
        {
          title: "item-2",
          text: GPPLUS2,
        },
        {
          title: "item-3",
          text: GPPLUS3,
        },
      ],
    },
  },

  getStartedCta: {
    pt: [40, 40, 64, 80],
    pb: [40, 40, 64, 80],
    title: "Get started with your personalized GrowthPlan+.",
    copy: "*Learn more about our GrowthPlan+ Guarantee.",
    button: {
      text: "Schedule your consultation",
      url: "/men/growthplanplus/scheduling/",
    },
    disclaimer:
      "A GrowthPlan+ subscription is $299/month. You will book your first consultation now and will be charged $299 + applicable taxes when you complete checkout. In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you.",
  },
}
